import React, { useEffect, useState } from "react";
import Head from "next/head";
import getConfig from "next/config";

import { renderer } from "@/utils/bootstrap";
import Analytics, {
  setJLData,
} from "@JohnLewisPartnership/jlfs-ui-library/dist/components/analytics/Analytics";
import LiveChat from "@JohnLewisPartnership/jlfs-ui-library/dist/components/liveChat/liveChat";
import { structuredData } from "@JohnLewisPartnership/jlfs-ui-library/dist/utils/structured-data/structuredData";

import { useRouter } from "next/router";
import { TResPageData } from "@JohnLewisPartnership/jlfs-ui-library/dist/types/restResolvedTypes";

export default function RootLayout({
  children,
  pageProps,
}: {
  children: React.ReactNode;
  pageProps: {
    pageData: TResPageData;
    slug: string;
    preview: boolean;
    host: string;
  };
}) {
  const [currentUrl, setCurrentUrl] = useState<string>();
  const { publicRuntimeConfig } = getConfig();

  const { asPath } = useRouter();
  const { pageData, slug, host } = pageProps;

  const pageUrl = `${publicRuntimeConfig.baseUrl}${asPath}`;

  const pageFooter = pageData?.fields.pageFooter;
  const pageHeader = pageData?.fields.pageHeader;
  // @ts-ignore - temporary - pageLogin is not defined
  const pageLogin = pageData?.fields.pageLogin;
  const metadata = pageData?.fields.pageMetadata.fields;

  let robotsContent = [];

  if (metadata?.noIndexIndicator) {
    robotsContent.push("noindex");
  }
  if (metadata?.noFollowIndicator) {
    robotsContent.push("nofollow");
  }
  if (pageHeader && pageLogin) {
    pageHeader.fields["login"] = pageLogin;
  }

  const hasChat =
    publicRuntimeConfig.chatAPIKey &&
    publicRuntimeConfig.chatName &&
    slug === "home-insurance";

  useEffect(() => {
    setCurrentUrl(window.location.href);
    if (pageData?.fields.pageMetadata) {
      setJLData(pageData.fields.pageMetadata);
    }
  }, []);

  return (
    <>
      <Head>
        {metadata?.title && <title>{metadata.title}</title>}
        {metadata?.description && (
          <meta name="description" content={metadata.description} />
        )}
        <link
          rel="canonical"
          href={
            // deepcode ignore DOMXSS
            metadata?.canonicalTag || currentUrl
          }
        />
        {robotsContent.length > 0 && (
          <meta name="robots" content={robotsContent.join(",")} />
        )}
        {hasChat && (
          <LiveChat
            apiKey={publicRuntimeConfig.chatAPIKey}
            name={publicRuntimeConfig.chatName}
          />
        )}
        {structuredData(pageData, pageUrl)}
      </Head>
      <Analytics />
      {pageHeader && renderer(pageHeader, pageProps.preview)}
      <main>{children}</main>
      {pageFooter && renderer(pageFooter, pageProps.preview)}
    </>
  );
}
